<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">My Incoming Call Logs</div>
        <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <div class="service-plan-box-outer">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="call-label">Bill Duration : </div>
                    <div class="call-count ps-1">
                        <span v-if="!loading">{{ totalcallduration ? totalcallduration : 0 }}</span>
                        <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-icon-loader-btn
                                  " role="status" v-if="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" @click="voterFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
                title="Filter">
                <span class="custom-filter-apply-alert"></span>
                <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist" v-if="this.user_team_status_allow == 1">
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="myvoter-all-details-tab" data-bs-toggle="tab"
                data-bs-target="#myvoteralldetail" type="button" role="tab" aria-controls="vehicle-details"
                aria-selected="true"><i class="pi pi-user me-1" :style="{ fontSize: '0.7rem' }"></i>My Incoming Call Logs
            </button>
        </li>
        <li class="nav-item" role="presentation">
            <button class="nav-link" id="teamvoter-report-tab" data-bs-toggle="tab" data-bs-target="#teamvoterreport"
                type="button" role="tab" aria-controls="customer-details" aria-selected="false"
                @click="redirectteamvoterurl()"><i class="pi pi-users me-1" :style="{ fontSize: '0.7rem' }"></i>Teams
                Incoming Call Logs
            </button>
        </li>
    </ul>
    <div class="tab-content mt-2" id="myTabContent">
        <div class="tab-pane fade show active" id="myvoteralldetail" role="tabpanel"
            aria-labelledby="myvoter-all-details-tab">
            <div class="custom-ultima-datatable"
                v-bind:style="[this.user_team_status_allow == 1 ? { height: 'calc(100vh - 188px)' } : { height: 'calc(100vh - 185px)' }]">
                <DataTable :value="incomingCallLogsList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
                    :paginator="true" :rows="10" :lazy="true" dataKey="li1" :rowHover="true" :totalRecords="totalRecords"
                    @page="changePage($event)"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :loading="loading">
                    <template v-if="!loading" #empty>No records found.</template>
                    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
                    <Column field="datatime" header="Date Time" headerStyle="width: 17%" bodyStyle="width: 17%">
                        <template #body="{ data }">
                            <div>
                                {{ format_datetime(data.datetime) }}
                            </div>
                        </template>
                    </Column>
                    <Column field="virtualnumber" header="Virtual Number" headerStyle="width: 10%" bodyStyle="width: 10%">
                        <template #body="{ data }">
                            <div class="text-capitalize">{{ data.li8 ? data.li8 : "N/A" }}
                            </div>
                        </template>
                    </Column>
                    <Column field="callernumber" header="Caller Number" headerStyle="width: 10%" bodyStyle="width: 10%">
                        <template #body="{ data }">
                            <div>
                                {{ data.li12 ? data.li12 : "N/A" }}
                            </div>
                        </template>
                    </Column>
                    <Column field="callstatus" header="Call Status" headerStyle="width: 10%" bodyStyle="width: 10%">
                        <template #body="{ data }">
                            <span>{{ data.li18 ? data.li18 : "N/A" }}</span>
                        </template>
                    </Column>
                    <Column field="callanswer" header="Call Answer By" headerStyle="width: 20%" bodyStyle="width: 20%">
                        <template #body="{ data }">
                            <span class="text-capitalize">{{ data.li31 ? data.li31 : "N/A" }}</span>
                        </template>
                    </Column>
                    <Column field="callduration" header="Call Duration" headerStyle="width: 8%" bodyStyle="width: 8%">
                        <template #body="{ data }">
                            <span>{{ data.li14 ? data.li14 : "00:00:00" }}</span>
                        </template>
                    </Column>
                    <Column field="billduration" header="Bill Duration" headerStyle="width: 8%" bodyStyle="width: 8%">
                        <template #body="{ data }">
                            <span>{{ data.li46 ? data.li46 : "00:00:00" }}</span>
                        </template>
                    </Column>
                    <Column field="charges" header="Charges Status" headerStyle="width: 17%" bodyStyle="width: 17%">
                        <template #body="{ data }">
                            <div>
                                <span v-if="data.li33 == 1" class="status-active">Free of Cost</span>
                                <span v-if="data.li33 == 2" class="status-inactive">Chargeble</span>
                                <span v-if="data.li33 == null || data.li33 == ''">N/A</span>
                            </div>
                            <div v-if="data.li22 != null && data.li22 != ''" class="mt-2">
                                <audio controls controlsList="download">
                                    <source :src="data.li22" type="audio/mpeg" />
                                </audio>
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="tab-pane fade" id="teamvoterreport" role="tabpanel" aria-labelledby="teamvoter-report-tab"
            v-if="this.user_team_status_allow == 1">
            Teams Incoming Call Logs
        </div>
    </div>
    <!-- filter start here -->
    <div class="modal-mask" v-if="voterfiltermodalsflag">
        <div class="
        modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" class="btn-close" @click="voterFilterModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">From Date</label>
                                <Calendar v-model="fromdatein" :showIcon="true" class="custom-ultima-calendar"
                                    placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate"
                                    :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">To Date</label>
                                <Calendar v-model="todatein" :disabled="fromdatein == null" :showIcon="true"
                                    class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                    :minDate="fromdatein" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Voter Mobile No.</label>
                                <div class="input-group custom-input-group">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                            label="label" placeholder="Select" disabled
                                            class="multiselect-custom custom-title-multiselect" :canClear="true"
                                            :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                            :canDeselect="false" />
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-12">
                                        <input type="text" v-model="li12" class="form-control" id="formMobileNoInput"
                                            placeholder="Enter Mobile No." maxlength="10" @keypress="onlyNumberMobile"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Call Status</label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="li18" name="callstatus-option"
                                        id="misscall" autocomplete="off" value="Missed" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="misscall">Missed</label>
                                    <input type="radio" class="btn-check" v-model="li18" name="callstatus-option"
                                        id="connectedcall" autocomplete="off" value="Connected" />
                                    <label class="btn text-capitalize" for="connectedcall">Connected</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Voter Name</label>
                                <input type="text" v-model="li32" @keypress="isLetterWithSpace($event)"
                                    class="form-control text-capitalize" placeholder="Enter Voter Name" maxlength="30"
                                    autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Select User</label>
                                <Multiselect v-model="searchuser_fk" :options="activeUserList" :searchable="true"
                                    label="label" placeholder="Select" class="multiselect-custom text-capitalize"
                                    :canClear="true" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                    appendTo="body" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Charges Status</label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="li33" name="charges-option" id="freecost"
                                        autocomplete="off" value="1" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="freecost">Free of
                                        Cost</label>
                                    <input type="radio" class="btn-check" v-model="li33" name="charges-option"
                                        id="chargeble" autocomplete="off" value="2" />
                                    <label class="btn text-capitalize" for="chargeble">Chargeble</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn"
                                :disabled="fromdatein == null && todatein == null && li12 == '' && li18 == '' && li32 == '' && li33 == '' && searchuser_fk == ''"
                                @click="
                                    btnFilterSearch(li12, li18, li32, li33, searchuser_fk)
                                    ">
                                Apply Filter
                            </button>
                            <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                                Reset All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
</template>
<script>
import ApiService from "../../../service/ApiService";
import commonMixin from "../../../mixins/commonMixin.js";
import moment from 'moment';
export default {
    mixins: [commonMixin],
    data() {
        return {
            incomingCallLogsList: null,
            totalRecords: 0,
            loading: false,
            totalcallduration: 0,
            voterfiltermodalsflag: false,
            page_no: 0,
            countryCodeList: [],
            countrycode: { value: 101, label: "+91" },
            todaysDate: new Date(),
            fromdatein: null,
            todatein: null,
            li12: '',
            li18: '',
            searchuser_fk: [],
            li32: '',
            li33: '',
            activeUserList: [],
            searchuservalue: '',
            user_info: '',
            user_team_status_allow: 0,
        };
    },
    mounted() {
        this.getIncomingCallLogs({ isTeam: 0 });
        this.getActiveUsers();
        this.user_info = JSON.parse(localStorage.user);
        this.user_team_status_allow = this.user_info.is_team;
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        redirectteamvoterurl() {
            this.$router.push("/logs/teamsincoming");
        },
        getIncomingCallLogs(e) {
            this.loading = true;
            this.ApiService.getIncomingCallLogs(e).then((data) => {
                if (data.success == true) {
                    this.incomingCallLogsList = data.data;
                    this.totalcallduration = data.totalBillingDuration;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.incomingCallLogsList = null;
                    this.totalRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getIncomingCallLogs({
                page_no: this.page_no,
                isTeam: 0,
                fromdate: this.fromdatein,
                todate: this.todatein,
                li12: this.li12,
                li18: this.li18,
                li31: this.searchuser_fk,
                li32: this.li32,
                li33: this.li33
            });
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        voterFilterModalOpen() {
            this.voterfiltermodalsflag = true;
            if (this.fromdatein != null) {
                this.fromdatein = new Date(this.fromdatein);
            }
            if (this.todatein != null) {
                this.todatein = new Date(this.todatein);
            }
        },
        voterFilterModalClose() {
            this.voterfiltermodalsflag = false;
        },
        btnFilterSearch(li12, li18, li32, li33, event_searchuser
        ) {
            if (this.fromdatein != null && this.todatein != null) {
                this.fromdatein = moment(this.fromdatein).format('YYYY-MM-DD');
                this.todatein = moment(this.todatein).format('YYYY-MM-DD');
            }
            this.searchuser_fk = event_searchuser;
            this.li12 = li12;
            this.li18 = li18;
            this.li32 = li32;
            this.li33 = li33;
            this.voterfiltermodalsflag = false;
            this.getIncomingCallLogs({
                page_no: this.page_no,
                isTeam: 0,
                fromdate: this.fromdatein,
                todate: this.todatein,
                li12: this.li12,
                li18: this.li18,
                li31: this.searchuser_fk.label,
                li32: this.li32,
                li33: this.li33,
            });
        },
        resetVoterFilter() {
            this.fromdatein = null;
            this.todatein = null;
            this.li12 = '';
            this.li18 = '';
            this.searchuser_fk = '';
            this.li32 = '';
            this.li33 = '';
            this.getIncomingCallLogs({
                page_no: this.page_no,
                isTeam: 0
            });
            this.voterfiltermodalsflag = false;
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        getActiveUsers(e) {
            this.ApiService.getActiveUsers(e).then((data) => {
                if (data.status == 200) {
                    this.activeUserList = data.data;
                    for (let i = 0; i < this.activeUserList.length; i++) {
                        let designation = '';
                        if (this.activeUserList[i]['ak84'] != null && this.activeUserList[i]['ak84'] != '') {
                            designation = ' (' + this.activeUserList[i]['ak84'] + ')';
                        }
                        this.activeUserList[i]['label'] = this.activeUserList[i]['label'] + '' + designation;
                    }
                } else {
                    this.activeUserList = null;
                }
            });
        },
    },
};
</script>
<style scoped>
.status-active {
    background: #c8e6c9;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #4daa53;
    line-height: 12px;
}

.status-inactive {
    background: #ffcdd2;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #c63737;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #d7a5a5;
    line-height: 12px;
}

.service-plan-box-outer {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    padding: 6px 10px;
}

.service-plan-box-outer .call-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    user-select: none;
}

.service-plan-box-outer .call-count {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>